<!--
 * @Description: 设备管理
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\views\device\DeviceList.vue
-->
<template>
  <div class="device-list">
    <building-tree @node-click="selectBuilding" />
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
    >
    </base-list-page>
  </div>
</template>

<script>
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { baseTableProperty, colProperty } from "@/components/baseTable/baseTableProperty";
import { ApiFamilyDevice } from "@/api/space";

export default {
  name: "DeviceList",
  data() {
    return {
      searchModel: null,
      tableModel: null,
      paginationModel: new basePaginationProperty(),
      realestateId: null,
      projectId: null,
      buildingCode: null
    };
  },
  created() {
    this.initSearch();
    this.initTableList();
  },
  methods: {
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("房屋名称", "familyName"),
        searchFormItemProperty.Init("设备名称", "deviceName"),
        searchFormItemProperty.Init("设备编码", "deviceSn")
      ];
      searchModel.setItems(items);
      searchModel.showReset = true;
      this.searchModel = searchModel;
    },
    initTableList() {
      let model = new baseTableProperty();
      model.colProperty = [
        colProperty.Init("房屋编号", "familyCode"),
        colProperty.Init("房屋名称", "familyName"),
        colProperty.Init("设备编码", "deviceSn"),
        colProperty.Init("设备名称", "deviceName"),
        colProperty.Init("设备位置", "roomName"),
        colProperty.Init("设备状态", "onlineFlagStr")
      ];
      this.tableModel = model;
    },
    refreshTableData() {
      let params = {
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        realestateId: this.realestateId,
        projectId: this.projectId,
        buildingCode: this.buildingCode,
        ...this.searchModel.getItemsValue()
      };
      ApiFamilyDevice.list(params).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    // 选择楼盘
    selectBuilding(value) {
      this.realestateId = value[0];
      this.projectId = value[1];
      this.buildingCode = value[2];
      this.paginationModel.currentPage = 1;
      this.refreshTableData();
    }
  }
};
</script>

<style lang="scss" scoped>
.device-list {
  display: flex;
  height: 100%;
  ::v-deep .detail-info {
    flex: 1;
  }
}
</style>
