/*
 * @Description: 空间管理api
 * @Author: zhongyuanrui
 * @LastEditors: zhongyuanrui
 * @FilePath: \homeauto-web-v3.0\src\api\space.js
 */
import request from "@/plugins/axios";

export const ApiSpace = {
  // 分页
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/space-manage/list",
      method: "get",
      params
    });
  },
  // 修改大屏
  updateScreen(params) {
    return request({
      url: "/homeauto-center-device/device/web/family/update/mac-ip",
      method: "post",
      params
    });
  }
};

export const ApiFamilyDevice = {
  // 分页
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/family-device-manage/list",
      method: "get",
      params
    });
  },
  detail(params) {
    return request({
      url: "/homeauto-center-device/device/web/family-device-manage/device/tetail",
      method: "get",
      params
    });
  }
};
